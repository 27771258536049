<template>
  <div class="newinfo-detail">
    <div class="container">
      <div class="title">
        <h1>{{ data.title }}</h1>
        <div class="release-date">
          <span class="from" v-if="data.sourceFrom">来源：{{data.sourceFrom}}</span>
          <span>发布时间：</span>
          <span>{{ data.createTime|filterDate }}</span>
        </div>
      </div>
      <!-- 内容 -->
      <div class="content">
        <div
          class="content-text"
          v-html="data.content"
        ></div>
      </div>
      <video v-if="$route.query.video" controls :poster="data.videoCover ? baseURL+ data.videoCover: ''" width="1200" height="500" :autoplay="false" :src="baseURL + attachFilePath[0]"></video>
      <FileList title="附件" v-if="attachFilePath.length > 0" :fileList="attachFilePath"></FileList>


    </div>
  </div>
</template>

<script>
import FileList from '@/components/FileList/index.vue'
import informNoticeApi from "@/api/informNotice";
import { mapState } from "vuex";

export default {
  components: {
    FileList
  },
  computed: {
    ...mapState(["baseURL"])
  },
  data(){
    return {
      id: null,
      data: {},
      attachFilePath: []
    }
  },
  filters: {
    filterElectronicType(val) {
      if (val == 0) return "非电子标";
      if (val == 1) return "电子标";
    },
    filterTime(val) {
      return moment(val).format("YYYY-MM-DD HH:mm:ss");
    },
    filterDate(val) {
      return moment(val).format("YYYY-MM-DD");
    }
  },
  async created() {
    console.log('this.$route.query', this.$route.query);
    this.id = this.$route.query.id
    let {data} = await informNoticeApi.findInformNoticeDetail({
      id: this.id
    });
    this.data = data
    if (this.data.attachFilePath) {
      this.attachFilePath = JSON.parse(this.data.attachFilePath)
    }
  }
}
</script>

<style lang="less" scoped>
.newinfo-detail {
  margin-bottom: 32px;
    .title {
      text-align: center;
      padding: 20px 0;
      border-bottom: 1px dashed rgba(236, 236, 241, 1);
      h1 {
        color: #20201E;
        font-size: 22px;
        margin-bottom: 10px;
        font-weight: bold;
      }

      .release-date {
        color: #999999;
        font-size: 14px;
        position: relative;
        .from {
          position: absolute;
          left: 0;
          margin-left: 20px;
        }
      }
    }
    // 内容
    .content {
      margin-bottom: 20px;
      margin-top: 20px;
      .content-title {
        padding-left: 10px;
        border-left: 5px solid #3660d9;
        margin-bottom: 20px;
      }

      .content-text {
        color: rgba(73, 74, 76, 1);
        font-size: 16px;
        margin-bottom: 20px;
        line-height: 28px;
        width: 100%;
        overflow-x: auto;
        /deep/ img {
          max-width: 100% !important;
        }
        /deep/ section, /deep/ div, /deep/ p {
          line-height: 28px !important;
        }

        /deep/ div {
          margin-bottom: 5px;
        }

        font-family: PingFang SC-Regular, PingFang SC;
      }

      
    }
}
</style>